<template>
  <div class="checkoutcart">
    <div class="row justify-content-center">
      <div class="col-md-8 my-md-5">
        <div class="p-3 p-md-0">
          <div class="position-relative m-4">
            <div class="progress bg-gray" style="height: 4px;">
              <div
                class="progress-bar bg-gray"
                role="progressbar"
                style="width: 50%;"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <button
              type="button"
              class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-secondary rounded-pill fs-10 fs-sm-5 fs-md-4 text-light step"
              style="width: 3rem; height:3rem;"
            >
              1
              <span
                class="fw-normal text-dark position-absolute text-nowrap step1"
                >購物車</span
              >
            </button>
            <button
              type="button"
              class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-secondary rounded-pill fs-10 fs-sm-5 fs-md-4 text-light step"
              style="width: 3rem; height:3rem;"
            >
              2
              <span
                class="fw-normal text-dark position-absolute text-nowrap step2"
                >填寫資料</span
              >
            </button>
            <button
              type="button"
              class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-primary rounded-pill fs-10 fs-sm-5 fs-md-4 step text-light"
              style="width: 3rem; height:3rem;"
            >
              3
              <span
                class="fw-normal text-dark position-absolute text-nowrap step3"
                >訂單確認</span
              >
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5" v-if="!isCloseLine">
        <a href="https://page.line.me/agd3292i?openQrModal=true">
          <img src="@/assets/img/line_banner.jpg" alt="" class="img-fluid d-none d-md-block">
        </a>
        <a href="https://page.line.me/agd3292i?openQrModal=true">
          <img src="@/assets/img/line_banner_m.jpg" alt="" class="img-fluid d-md-none">
        </a>
      </div>
      <div v-if="!isLogin">
        <section class="mb-5 mt-5">
          <div class="card rounded-0">
            <div class="card-header fw-bold bg-gray px-4 py-3">
              <h4>訂單資訊</h4>
            </div>
            <div class="card-body py-1 px-2 px-md-4 py-4">
              請於登入後到會員專區 - 訂單查詢確認
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-md-6">
              <div class="d-grid gap-2">
                <router-link to="/" class="btn btn-primary text-white">
                  繼續購物
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-else class="col-md-12 mb-5 mt-5">
        <!-- ATM付款付款成功 -->
        <div class="mb-3" v-if="userOrder.vAccount && userOrder.orderStatus !== 'S03'">
            <h5 class="text-primary mb-1">
                感謝您訂購商品，訂單成立!
            </h5>
            <p>以下為您的購物明細及轉帳資訊，並請於5天內繳納完畢。可選擇任一銀行或是郵局的ATM櫃員機轉帳，訂單商品將依據您轉帳成功的時間算起，3-5個工作天內出貨。</p>
            <p>提醒您，如繳款期限到期尚未繳款，此筆訂單將被取消，您選購的商品亦無法保留。並請見諒!</p>
        </div>
        <!-- 信用卡付款失敗 -->
        <h5 v-if="(userOrder.orderStatus !== 'S01' && userOrder.orderStatus !== 'S04' && userOrder.orderStatus !== 'S05') && !userOrder.vAccount" class="text-danger mb-4">
            {{ userOrder.orderStatusName }}
        </h5>
        <!-- 信用卡付款成功 -->
        <h5 v-if="(userOrder.orderStatus === 'S01' || userOrder.orderStatus === 'S04' || userOrder.orderStatus === 'S05') && !userOrder.vAccount" class="text-primary mb-4">
            {{ userOrder.orderStatus === 'S01' || userOrder.orderStatus === 'S04' ? '若您已成功付款，請於 20 分鐘後至會員專區 - 訂單查詢確認訂單狀態' : '感謝您訂購商品，訂單成立!' }}
        </h5>
        <div class="card rounded-0">
          <div class="card-header fw-bold bg-white px-4 py-3  border-2 border-dark">
            <h4>線上付款回覆資訊</h4>
          </div>
          <div class="card-body py-1 px-2 px-md-4">
            <table class="table onlinePay-table mb-0">
              <tbody>
                <tr>
                  <th scope="row" width="25%">訂單編號</th>
                  <td>{{ userOrder.orderNo }}</td>
                </tr>
                <tr>
                  <th scope="row">訂購時間</th>
                  <td v-if="userOrder.shoppingDate">{{ userOrder.shoppingDate.split('T')[0] }}  {{ userOrder.shoppingDate.split('T')[1].split('.')[0] }}</td>
                </tr>
                <tr>
                  <th scope="row">付款方式</th>
                  <td>{{ paymentMethodName }}</td>
                </tr>
                <tr>
                  <th scope="row">付款狀態</th>
                  <td v-if="!!userOrder.vAccount || userOrder.paymentMethod === 'P10' " class="text-danger align-middle" :class="{ 'border-0': !userOrder.vAccount }">
                    {{ userOrder.orderStatusName }}
                  </td>
                  <td v-else class="text-danger align-middle">
                    {{ userOrder.orderStatus === 'S01' || userOrder.orderStatus === 'S04'  ? '若您已成功付款，請於 20 分鐘後至會員專區 - 訂單查詢確認訂單狀態' : userOrder.orderStatusName }}
                  </td>
                </tr>
                <tr v-if="!!userOrder.vAccount && !userOrder.memo.includes('2024美容展訂單')">
                  <th scope="row" class="align-middle">銀行代號&名稱</th>
                  <td class="text-danger">803 聯邦商業銀行 營業部(0021)</td>
                </tr>
                <tr v-if="!!userOrder.vAccount && !userOrder.memo.includes('2024美容展訂單')">
                  <th scope="row">轉帳帳號</th>
                  <td>{{ userOrder.vAccount }}</td>
                </tr>
                <tr v-if="!!userOrder.vAccount && !userOrder.memo.includes('2024美容展訂單')">
                  <th scope="row">轉帳金額</th>
                  <td><span class="text-danger">{{ $currency.currency(userOrder.paidAmount) }}</span> 元</td>
                </tr>
                <tr>
                  <th scope="row" class="align-middle border-0">交易說明</th>
                  <td class="border-0">
                    <template v-if="userOrder.memo.includes('2024美容展訂單')">
                      <small class="text-danger d-block">
                        近來詐騙事件猖獗，請消費者提防詐騙，本商城不會要求客戶提供金融相關資訊，更不會要求 ATM 退費，請務必注意！
                      </small>
                    </template>
                    <template v-else>
                      親愛的會員您好：<br>
                      <span v-if="!!userOrder.vAccount">本訂單轉帳帳號為本訂單專屬，請抄下銀行代號、轉帳帳號及轉帳金額，並於繳款期限內進行付款，一經確認您的款項匯入本公司帳戶，將於3~5個工作日內出貨。</span>
                      <span v-if="userOrder.paymentMethod === 'P10' ">以下為您的購物明細及收件資訊，本訂單將於３－５個工作天內出貨。提醒您保持聯絡電話的暢通，物流士將會與您聯繫後續收貨及付款事宜。 </span>
                      <small class="text-danger d-block">
                        近來詐騙事件猖獗，請消費者提防詐騙，本商城不會要求客戶提供金融相關資訊，更不會要求 ATM 退費，請務必注意！
                      </small>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card rounded-0 mt-5">
          <div class="card-header fw-bold bg-white px-4 py-3 border-2 border-dark">
            <h4>本次訂購明細</h4>
          </div>
          <div class="card-body p-0">
            <table class="table text-center mb-0">
              <tbody>
                <tr>
                  <td class="ps-3 ps-md-4 text-start border-top-0 border-start-0 border-end-0 border-2" width="40%">
                    商品資料
                  </td>
                  <td class="d-none d-md-table-cell border-top-0 border-start-0 border-end-0 border-2">規格</td>
                  <td class="border-top-0 border-start-0 border-end-0 border-2">單價</td>
                  <td class="border-top-0 border-start-0 border-end-0 border-2">數量</td>
                  <td class="d-none d-md-table-cell border-top-0 border-start-0 border-end-0 border-2">小計</td>
                  <td class="pe-4 d-none d-md-table-cell border-top-0 border-start-0 border-end-0 border-2">配送方式</td>
                </tr>
                <tr v-for="item in userOrder.product" :key="item">
                  <td class="ps-3 ps-md-4 text-start">
                    {{ item.productNo }}
                  </td>
                  <td class="d-none d-md-table-cell">{{ item.option }}</td>
                  <td>${{ $currency.currency(item.price / item.quantity) }}</td>
                  <td>{{ item.quantity }}</td>
                  <td class="d-none d-md-table-cell">${{ $currency.currency(item.price) }}</td>
                  <td class="pe-4 d-none d-md-table-cell">宅配</td>
                </tr>
              </tbody>
            </table>
            <div class="px-2 px-md-4 border-top">
              <table class="table text-end mb-0">
                <tbody>
                  <tr>
                    <td width="65%">
                      本次訂單共買
                      <span class="text-primary" v-if="userOrder.product">{{ $currency.currency(userOrder.product.length) }}</span> 件，小計：
                    </td>
                    <td><span class="text-primary">{{ $currency.currency(userOrder.payableAmount) }}</span>元</td>
                  </tr>
                  <tr>
                    <td class="text-end align-middle">折扣優惠：</td>
                    <td><span class="text-primary">{{ $currency.currency(userOrder.payableAmount - userOrder.paidAmount - userOrder.bonus) }}</span>元</td>
                  </tr>
                  <tr v-if="userOrder.bonus">
                    <td class="text-end align-middle">紅利折抵：</td>
                    <td><span class="text-primary">{{ $currency.currency(userOrder.bonus) }}</span>元</td>
                  </tr>
                  <tr>
                    <td class="text-end align-middle">應付總金額應付(註)：</td>
                    <td class="text-end align-middle">
                      <p class="text-primary d-inline">
                        $ <span class="fs-5 fw-bold">{{ $currency.currency(userOrder.paidAmount) }}</span>
                      </p>
                      元
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-danger text-start text-md-end">
                      <small
                        >註：使用信用卡結帳時，此頁之紅利折抵、應付總金額為參考值，實際金額以線上授權完畢後為準。</small
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card rounded-0 mt-5">
          <div class="card-header fw-bold bg-white px-4 py-3 border-2 border-dark">
            <h4>收件人資訊</h4>
          </div>
          <div class="card-body py-1 px-2 px-md-4">
            <table class="table onlinePay-table mb-0 recipient">
              <tbody>
                <tr>
                  <th scope="row" width="25%" class="">姓名</th>
                  <td>{{ userOrder.consigneeName }}</td>
                </tr>
                <tr>
                  <th scope="row">手機</th>
                  <td>{{ userOrder.consigneePhone }}</td>
                </tr>
                <tr>
                  <th scope="row">電子郵件</th>
                  <td>{{ userOrder.consigneeEmail }}</td>
                </tr>
                <tr>
                  <th scope="row" class="border-0">地址</th>
                  <td class="border-0">{{ userOrder.city }}{{ userOrder.district }}{{ userOrder.address }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-md-6">
            <div class="d-grid gap-2">
              <router-link to="/" class="btn btn-primary text-white">
                繼續購物
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countryName from '@/assets/country.json'
import moment from 'moment'

export default {
  data () {
    return {
      orderId: '', // ?訂單編號
      countryName, // ?縣市鄉鎮代碼 json
      userOrder: {}, // ? API 取得的訂單明細
      isLogin: false, // ?判斷是否登入
      isCloseLine: false
    }
  },
  methods: {
    getOrder () {
      // ?會員訂單資訊查詢 API
      const url = `${process.env.VUE_APP_API}/api/members/orders?orderno=${this.orderId}`
      this.$http.get(url)
        .then((res) => {
          if (res.data.rtnCode === 0) {
            this.userOrder = res.data.info[0]
            // ?轉換縣市、鄉鎮代碼為中文
            this.userOrder.city = this.countryName[this.countryName.findIndex((item) => item.Country === this.userOrder.city)].CountryName
            this.userOrder.district = this.countryName[this.countryName.findIndex((item) => item.District === this.userOrder.district)].DistrictName
            // ?將購買商品傳入 Facebook pixel
            // this.purchaseFbq()
            // *將購買商品傳入 GA
            this.purchaseGA()
            // *將交易資訊傳入 GA
            this.GA_add_payment_info()
            // // *將運輸資訊傳入GA
            // this.GA_add_shipping_info()
            // *將購物車icon清零
            this.$store.commit('getcartNum', 0)
          } else {
            // ?未成功回傳訂單明細
            this.$router.push({ name: 'notfound', params: { msg: `${res.data.rtnMsg}(${res.data.rtnCode})` } })
          }
        })
    },
    purchaseFbq () {
      // ? Facebook Pixel 傳入總價格(在訂單確認頁面生成時送出)
      window.fbq('track', 'Purchase', {
        content_type: 'product',
        value: this.userOrder.paidAmount,
        currency: 'TWD'
      })
    },
    // * GA追蹤碼
    purchaseGA () {
      const datatoGA = {
        currency: 'TWD',
        value: this.userOrder.paidAmount,
        transaction_id: this.userOrder.orderNo,
        city: this.userOrder.city,
        // paymethod: this.userOrder.paymentMethodName,
        items: JSON.parse(JSON.stringify(this.userOrder.product))
      }
      datatoGA.items.forEach(item => {
        item.item_id = item.id
        item.name = item.productNo
        item.item_name = item.productNo
        item.currency = 'TWD'
        item.item_variant = item.option
        delete item.productNo
        delete item.shipmentStatus
        delete item.shipoutDate
        delete item.shipping
        delete item.shippingNo
      })
      this.$gtag.event('purchase', datatoGA)
    },
    GA_add_payment_info () {
      const dataForGA = {
        currency: 'TWD',
        value: this.userOrder.paidAmount,
        payment_type: this.userOrder.paymentMethodName,
        items: [{}]
      }
      dataForGA.items = JSON.parse(JSON.stringify(this.userOrder.product))
      dataForGA.items.forEach(item => {
        item.item_id = item.id
        item.item_name = item.productNo
        item.item_variant = item.option
        delete item.image
        delete item.id
        delete item.productNo
        delete item.option
        delete item.shipmentStatus
        delete item.shipoutDate
        delete item.shipping
        delete item.shippingNo
      })
      this.$gtag.event('add_payment_info', dataForGA)
    },
    GA_add_shipping_info () { // !未有新運輸方式，後端未吐無法追蹤
      const dataForGA = {
        currency: 'TWD',
        value: this.userOrder.paidAmount,
        shipping_tier: 'Ground',
        items: [{}]
      }
      dataForGA.items = JSON.parse(JSON.stringify(this.userOrder))
      dataForGA.items.forEach(item => {
        item.item_id = item.id
        item.item_name = item.productNo
        item.item_variant = item.option
        item.shipping_tier = item.shipping
        delete item.image
        delete item.id
        delete item.productNo
        delete item.option
        delete item.shipmentStatus
        delete item.shipoutDate
        delete item.shipping
        delete item.shippingNo
      })
    },
    getToken () {
      // ?判斷是否有登入(token取得)
      const AccessToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      const RefreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaRefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (AccessToken || RefreshToken) {
        return true
      } else {
        return false
      }
    },
    checkLinePromotionExpire () {
      const now = moment().format('YYYY/MM/DD HH:mm:ss')
      if (moment(now, 'YYYY/MM/DD HH:mm:ss').isAfter('2023/12/31 23:59:59')) {
        this.isCloseLine = true
      }
      if (moment(now, 'YYYY/MM/DD HH:mm:ss').isBefore('2022/06/13 15:30:00')) {
        this.isCloseLine = true
      }
    },
    // ?判斷是否為生日禮頁面導入(不需token)
    async getDataFromBirthday () {
      const url = `${process.env.VUE_APP_API}/api/members/OrdersWithoutToken?orderno=${this.$route.params.oid}&ShoppingDate=${this.$route.params.ts}`
      const res = await this.axios.get(url)
      this.isLogin = true
      if (res.data.rtnCode === 0) {
        this.userOrder = res.data.info[0]
        // ?轉換縣市、鄉鎮代碼為中文
        this.userOrder.city = this.countryName[this.countryName.findIndex((item) => item.Country === this.userOrder.city)].CountryName
        this.userOrder.district = this.countryName[this.countryName.findIndex((item) => item.District === this.userOrder.district)].DistrictName
        // ?將購買商品傳入 Facebook pixel
        // this.purchaseFbq()
        // *將購買商品傳入 GA
        this.purchaseGA()
        // *將交易資訊傳入 GA
        this.GA_add_payment_info()
        // // *將運輸資訊傳入GA
        // this.GA_add_shipping_info()
        // *將購物車icon清零
        this.$store.commit('getcartNum', 0)
      } else {
        // ?未成功回傳訂單明細
        this.$router.push({ name: 'notfound', params: { msg: `${res.data.rtnMsg}(${res.data.rtnCode})` } })
      }
    }
  },
  computed: {
    paymentMethodName () {
      const hasMemoKeyword = this.userOrder.memo && this.userOrder.memo.includes('2024美容展訂單')
      if (hasMemoKeyword && this.userOrder.paymentMethod === 'P05') {
        return '現金'
      }
      return this.userOrder.paymentMethodName
    }
  },
  watch: {
    '$route' (to, from) {
      sessionStorage.removeItem('checkCart')
    }
  },
  mounted () {
    this.checkLinePromotionExpire()
    if (this.$route.params.ts) {
      this.getDataFromBirthday()
      return
    }
    // ?判斷是否有登入
    if (this.getToken()) {
      this.isLogin = true
      this.orderId = this.$route.params.oid
      this.getOrder()
      return
    }
    this.isLogin = false
    if (document.body.clientWidth < 576) {
      // ? 手機版
      this.$router.push('/membermobilelogin')
    } else {
      // ? web 版
      this.$store.state.loginModal.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  background-color: #e9ecef;
}
.step {
  pointer-events: none;
}
.step1 {
  bottom: -80%;
  left: -4%;
  font-weight: bold;
  @media (min-width: 576px) {
    bottom: -100%;
    left: -25%;
  }
}

.step2,
.step3 {
  bottom: -80%;
  left: -25%;
  font-weight: bold;
  @media (min-width: 576px) {
    bottom: -100%;
    left: -50%;
  }
}

.onlinePay-table th,
.onlinePay-table td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.receiver-table td {
  padding-top: 0;
}

.card-header {
  border-radius: 0;
}
.recipient > :not(caption) > * > * {
    @media (max-width:768px) {
        border-bottom-width:0
    }
}
</style>
